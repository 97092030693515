<template>
  <div>
    <!-- 점검항목 -->
    <c-table
      ref="table"
      title="점검항목"
      :columns="grid.columns"
      :data="target.results"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="sopPreWorkCheckTargetResultId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable&&!disabled" 
            :showLoading="false" 
            label="LBLADD" icon="add" 
            @btnClicked="addResult" />
          <c-btn 
            v-if="editable&&!disabled" 
            :disabled="!target.results || target.results.length === 0"
            label="LBLREMOVE" 
            icon="remove" 
            @btnClicked="deleteResult" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable&&!disabled"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="sopPreWorkCheckTargetResultId"
            ibmTaskTypeCd="ITT0000195"
            ibmTaskUnderTypeCd="ITTU000220"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'preWorkCheckTargetResult',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          sopPreWorkCheckId: '',
        }
      },
    },
    target: {
      type: Object,
      default: function() {
        return {
          sopPreWorkCheckTargetId: '',  // 작업 전 안전점검 대상 일련번호
          sopPreWorkCheckId: '',  // 작업 전 안전점검 일련번호
          sopPreWorkCheckClassId: '',  // 작업 전 안전점검구분 일련번호
          targetIds: '',  // 대상 일련번호(복수)
          regUserId: '',  // 등록자 ID
          editFlag: '',
          results: [],
          deleteResults: [],
        }
      }
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목명',
            align: 'left',
            sortable: true,
            style: 'width:450px',
            type: 'text',
          },
          {
            name: 'checkItemSubName',
            field: 'checkItemSubName',
            label: '점검세부항목명',
            align: 'left',
            sortable: true,
            style: 'width:450px',
            type: 'text',
          },
          {
            name: 'pwcResultCd',
            field: 'pwcResultCd',
            label: '결과',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            codeGroupCd: 'PWC_RESULT_CD',
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLIMPRNO_PROGRESS',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
        ],
      },
      editable: true,
    };
  },
  computed: {
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['checkItemName'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    addResult() {
      this.target.results.splice(0, 0, {
        sopPreWorkCheckTargetResultId: uid(),  // 작업 전 안전점검 대상 결과 일련번호
        sopPreWorkCheckTargetId: this.target.sopPreWorkCheckTargetId,  // 작업 전 안전점검 대상 일련번호
        sopPreWorkCheckId: this.popupParam.sopPreWorkCheckId,  // 작업 전 안전점검 일련번호
        sopPreWorkCheckItemId: '',  // 작업 전 안전점검항목 일련번호
        checkItemName: '',  // 점검항목명
        pwcWorkerResultCd: 'PR00000001',  // 결과 (근로자)
        pwcManagerResultCd: 'PR00000001',  // 결과 (관리감독자)
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C',
      })
    },
    deleteResult() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.target.deleteResults) this.target.deleteResults = []
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.target.deleteResults, { sopPreWorkCheckTargetResultId: item.sopPreWorkCheckTargetResultId }) === -1 
            && item.editFlag !== 'C') {
              this.target.deleteResults.push(item)
          }
          this.target.results = this.$_.reject(this.target.results, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
